import React, {
  useEffect,
  useRef,
  createElement,
  Fragment,
  useState
} from 'react';
import {createRoot} from 'react-dom/client';
import {initializeAlgoliaClient} from '../algolia_listing_pages/utils/algolia_client';
import {
  autocomplete,
  getAlgoliaResults,
  getAlgoliaFacets
} from '@algolia/autocomplete-js';
import AlgoliaIndexMap from 'algolia_listing_pages/constants/AlgoliaIndexMap';
import useBreakpoint from '../hooks/useBreakpoint';
import searchParams from 'utils/url';

// custom renderers of results
import FacetResult from './../autocomplete/result-renderers/FacetResult.jsx';
import StyleResult from './../autocomplete/result-renderers/SimpleStyleResult.jsx';

// algolia plugins
import {createQuerySuggestionsPlugin} from '@algolia/autocomplete-plugin-query-suggestions';
import {createLocalStorageRecentSearchesPlugin} from '@algolia/autocomplete-plugin-recent-searches';
import {
  handleAutocompleteSelection,
  passThruSearchParams
} from 'algolia_listing_pages/utils/autoCompleteHelpers';
import {
  getOctoIdFromCookie,
  getSessionFromCookie
} from '../algolia_listing_pages/utils/data_utils';

const Autocomplete = (props) => {
  const railsEnv = props.railsEnv || props.rails_env || 'staging';
  const currentSearchParams = searchParams();
  const iframed = currentSearchParams.get('iframer') || false;
  const compatibleTypeFilter =
    currentSearchParams.get('compatible_type') ||
    props.compatibleTypeFilter ||
    '';
  const indexName = AlgoliaIndexMap[railsEnv] + '_styles';
  const suggestedQueriesIndexName =
    AlgoliaIndexMap[railsEnv] + '_styles_query_suggestions';
  const containerRef = useRef(null);
  const panelRootRef = useRef(null);
  const rootRef = useRef(null);
  const breakpoint = useBreakpoint();

  const useInsights = props.insights || true;
  const populateOnEmpty = props.populateOnEmpty || false;
  const useRecentSearches = compatibleTypeFilter === '';
  const useQuerySuggestions = compatibleTypeFilter === '';
  const placeholderText =
    props.placeholder || 'Search for t-shirts, hoodies, koozies...';
  const emptyQueryLegend = props.emptyQueryLegend || '';
  const showFacetCounts = props.showFacetCounts || false;
  const hitsToShow = props.hitsToShow || 6;
  const searchClient = props.algoliaClient || initializeAlgoliaClient();
  let facets = props.facets;
  const userToken = getOctoIdFromCookie() || getSessionFromCookie();
  if (compatibleTypeFilter !== '') facets = [];

  // PLUGINS
  const plugins = [];
  const handleCiHeaderLoaded = () => {
    if (!iframed) {
      document.querySelector('.pc-SearchTarget').style.display = 'none';
      document.querySelector('.aa-Autocomplete').style.display = 'none';
    }
  };

  useEffect(() => {
    window.addEventListener('ciHeaderLoaded', handleCiHeaderLoaded);
    return () => {
      window.removeEventListener('ciHeaderLoaded', handleCiHeaderLoaded);
    };
  }, []);

  if (useRecentSearches) {
    const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
      key: 'ALG_CAT_RECENT_SEARCH',
      limit: 6,
      transformSource({source}) {
        return {
          ...source,
          onSelect: ({item}) =>
            handleAutocompleteSelection(searchClient, indexName, item.label),
          templates: {
            header() {
              return (
                <>
                  <span className="aa-SourceHeaderTitle">Recent Searches</span>
                </>
              );
            },
            ...source.templates
          }
        };
      }
    });
    plugins.push(recentSearchesPlugin);
  }

  if (useQuerySuggestions) {
    const querySuggestionsPlugin = createQuerySuggestionsPlugin({
      searchClient,
      indexName: suggestedQueriesIndexName,
      getSearchParams({state}) {
        return {
          hitsPerPage: state.query ? 6 : 4,
          clickAnalytics: useInsights
        };
      },
      transformSource({source}) {
        return {
          ...source,
          onSelect({item}) {
            handleAutocompleteSelection(searchClient, indexName, item.query);
          },
          templates: {
            header() {
              return (
                <>
                  <span className="aa-SourceHeaderTitle">Suggestions</span>
                </>
              );
            },
            ...source.templates
          }
        };
      }
    });
    plugins.push(querySuggestionsPlugin);
  }

  useEffect(() => {
    if (!containerRef.current || process.env.NODE_ENV === 'test') {
      return undefined;
    }

    document.body.classList.add('autocomplete-theme');
    if (containerRef.current.children.length === 0) {
      const search = autocomplete({
        container: containerRef.current,
        insights: useInsights,
        renderer: {createElement, Fragment, render: () => {}},
        render({children}, root) {
          if (!panelRootRef.current || rootRef.current !== root) {
            rootRef.current = root;
            panelRootRef.current?.unmount();
            panelRootRef.current = createRoot(root);
          }
          panelRootRef.current.render(children);
        },
        placeholder: placeholderText,
        openOnFocus: true,
        detachedMediaQuery: breakpoint === 'sm' ? '' : 'none',
        getSources({query}) {
          let mappedFilters = '(status:active)';
          let facetFilters = [];
          if (compatibleTypeFilter)
            facetFilters.push(`compatible_type:${compatibleTypeFilter}`);

          const sources = [];

          facets.forEach((facet) => {
            sources.push({
              sourceId: `facet_${facet.name}`,
              getItems({query}) {
                if (!populateOnEmpty && !query) return [];
                return getAlgoliaFacets({
                  searchClient,
                  queries: [
                    {
                      indexName: indexName,
                      facet: facet.name,
                      params: {
                        facetQuery: query,
                        maxFacetHits: facet.showNum || 6,
                        clickAnalytics: useInsights
                      }
                    }
                  ]
                });
              },
              templates: {
                header({items}) {
                  if (!items.length) return null;
                  return (
                    <>
                      <span className="aa-SourceHeaderTitle">
                        {facet.caption}
                      </span>
                    </>
                  );
                },
                item({item, components}) {
                  return (
                    <FacetResult
                      hit={item}
                      components={components}
                      showCounts={showFacetCounts}
                    />
                  );
                }
              },
              onSelect: ({item}) =>
                handleAutocompleteSelection(searchClient, indexName, item.label)
            });
          });

          sources.push({
            sourceId: 'styles',
            getItems() {
              if (!populateOnEmpty && !query) return [];
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: indexName,
                    query,
                    params: {
                      hitsPerPage: hitsToShow,
                      filters: mappedFilters,
                      facetFilters: facetFilters,
                      clickAnalytics: useInsights
                    }
                  }
                ]
              });
            },
            templates: {
              header({items}) {
                if (!items.length) return null;
                return (
                  <>
                    <span className="aa-SourceHeaderTitle">
                      Results for Styles
                    </span>
                  </>
                );
              },
              item({item, components}) {
                return <StyleResult hit={item} components={components} />;
              },
              footer() {},
              noResults() {
                if (!query && iframed) return 'Start typing for results.';
                if (!query) return emptyQueryLegend || '';
                return 'No results.';
              }
            },
            onSelect({item}) {
              let path = '';
              if (item.target_url) path = item.target_url;
              if (item.breadcrumbs && item.breadcrumbs.length) {
                path = item.breadcrumbs[item.breadcrumbs.length - 1].path;
              }
              window.location = passThruSearchParams(path);
            }
          });

          return sources;
        },
        plugins: plugins,
        initialState: {
          query: searchParams(window.location.href).get('keyword') || ''
        },
        ...props
      });
      const detachedTrigger = document.querySelector('.pc-SearchTarget');
      if (detachedTrigger) {
        detachedTrigger.addEventListener('click', () => {
          search.setIsOpen(true);
          const el = document.querySelector('.aa-Input');
          if (el) {
            el.setAttribute('autofocus', 'autofocus');
            el.focus();
          }
        });
      }
      $(document).on('keypress', '.aa-Input', (evt) => {
        if (evt.keyCode === 13) {
          const qry = $('.aa-Input').val();
          if (qry.trim() === '') return;
          handleAutocompleteSelection(searchClient, indexName, qry.trim());
        }
        if (evt.key === ' ') {
          const el = $('.aa-Input');
          let qry = el.val() + evt.key;
          // remove 2+ spaces
          while (qry.indexOf('  ') >= 0) {
            qry = qry.replace(/  /g, ' ');
          }
          if (qry.endsWith(' ')) qry = qry.substring(0, qry.length - 1);
          el.val(qry);
        }
      });

      if (typeof window.aa === 'function' && userToken) {
        window.aa('setUserToken', userToken);
      }

      return () => {
        search.destroy();
      };
    }
  }, [props, breakpoint]);

  return (
    <>
      {iframed && (
        <div className="pc-ToolBar-autocomplete" data-testid="autocomplete-search">
          <div ref={containerRef} />
        </div>
      )}
    </>
  );
};

export default Autocomplete;
