import React from 'react';
import {navPath} from './utils/route_utils';

function Breadcrumbs(props) {
  const {categoryName, searchParams} = props;
  let {breadcrumbs} = props;
  if (!breadcrumbs) {
    breadcrumbs = [
      {name: 'All Products', path: '/products'},
      {name: categoryName}
    ];
  }
  const iframed = searchParams.get('iframer') || false;

  return (
    <div className="pc-Styles-breadcrumbs">
      <div className="pc-Breadcrumbs">
        {iframed && <div className="pc-SearchTarget" data-testid="search" />}
        <ol className="pc-Breadcrumbs-wrapper sb-Util-plainList">
          {breadcrumbs?.map((breadcrumb, index, {length}) => {
            if (index + 1 === length) {
              return (
                <li className="pc-BreadcrumbItem" key={breadcrumb.name}>
                  <span className="pc-Breadcrumb pc-Breadcrumb--category">
                    {' '}
                    {breadcrumb.name}{' '}
                  </span>
                </li>
              );
            }
            return (
              <li className="pc-BreadcrumbItem" key={breadcrumb.name}>
                <a
                  href={navPath(breadcrumb.path, searchParams)}
                  className="pc-Breadcrumb pc-Breadcrumb--parentCategory">
                  {' '}
                  {breadcrumb.name}{' '}
                </a>
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
}

export default Breadcrumbs;
